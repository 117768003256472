import React, { Component } from 'react';
import './App.css';
import './css/theme.css'
import Main from './Main'
import Privacy from './pages/Privacy'
import About from './pages/about'
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Header from './components/header'
import Footer from './components/footer'


class App extends Component {

    render() {

        return (
            <>
                <BrowserRouter >

                    <Switch location={window.location}>
                        <Route path="/" exact component={Main} />
                        <Route path='/privacy' component={Privacy} />
                        <Redirect to='/' />
                    </Switch>

                    <Footer />
                </BrowserRouter>
            </>
        );
    }

}

export default App;
