import React, { Component } from 'react';
import { NavLink, Link, withRouter } from "react-router-dom";

class footer extends Component {
    render() {
        return (
            <>
                <div className="footer-big-menu" id="contact_id">
                    <div className="row">
                        <div className="col-12">
                            <div className="cta text-center">
                                <h1 className="cta-title">
                                    Getting started is easy.<br />Send us a quick email and we will contact you asap!
                                </h1>
                                <a className="btn-shadow btn-shadow-info mr-md-1" href="mailto:info@cayland.com">
                                    Contact Us Today
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="bottom newsletter">
                        <p className="text-center">COPYRIGHT © 2020 CAYLAND SOLUTIONS INC. - ALL RIGHTS RESERVED</p>
                    </div>
                    <div className="bottom newsletter">

                        <Link to={"/privacy"} >Privacy</Link>
                    </div>

                </div >
            </>
        )
    }
}

export default footer;