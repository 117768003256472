import React from "react";
import data from "../data.js";

export default () => (
  <>

    <div className="about-2-hero" id="home_id">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>
              At Cayland Solutions, we want to help our <strong>clients succeed</strong> and make the biggest impact they can.
          </h1>
            <img src="images/unsplash/lee-campbell-86958.jpg" className="pic2 rounded d-none d-lg-inline"
              data--280-top="transform:translateY(-70px);"
              data-bottom="transform:translateY(0px);" />

            <img src="images/unsplash/andrew-neel-237802.jpg" className="pic1 rounded img-fluid"
              data--220-top="transform:translateY(-200px);"
              data-100-bottom="transform:translateY(0px);" />
          </div>
        </div>
      </div>
    </div>

    <div className="pricing-features">
      <div className="container">
        <section className="features">
          <div className="row">
            <div className="col-md-6 feature">
              <img src="images/icons/outline-color-user.png" className="icon" />
              <section>
                <h4>
                  Customer Centric
                </h4>
                <p>
                  We listen and strive to exceed your expectations and goals in order to meet your company objectives.
              </p>
              </section>
            </div>
            <div className="col-md-6 feature">
              <img src="images/icons/outline-color-settings.png" className="icon" />
              <section>
                <h4>
                  Critical Thinking
                </h4>
                <p>
                  Our team will skillfully analyze and assess each customer’s needs to determine the best solutions for their business problems.
                </p>
              </section>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 feature">
              <img src="images/icons/outline-color-random.png" className="icon" />
              <section>
                <h4>
                  Problem Solving
                </h4>
                <p>
                  Our goal is to solve all problems efficiently, whether simple or complex, to reach a successful outcome.
                </p>
              </section>
            </div>
            <div className="col-md-6 feature">
              <img src="images/icons/features-icon-ok.png" className="icon" />
              <section>
                <h4>
                  Integrity
                </h4>
                <p>
                  We believe in being honest and forthright with our customers and our employees.
                </p>
              </section>
            </div>
          </div>
        </section>
      </div>
    </div>
  </>
);
