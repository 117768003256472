import React, { Component } from 'react'
import { NavLink } from "react-router-dom";

const deviceMobile = /iphone|ipod|android|blackberry|opera mini|opera mobi|skyfire|maemo|windows phone|palm|iemobile|symbian|symbianos|fennec/i.test(navigator.userAgent.toLowerCase());

export default class header extends Component {
    state = {
        toggled: false
    }

    handleToggle = () => {
        this.setState({ toggled: !this.state.toggled })
    }
    render() {
        return (
            <nav className="navbar navbar-expand-md fixed-top navbar-light" role="navigation">
                <div className="container no-override">

                    <a className="navbar-brand" href="index.html">
                        <img src="./logo.png" alt="Cayland Solutions"
                            className="d-block d-lg-inline mr-2 w-15" style={{ height: 50 }} />

                    </a>
                    <button className="navbar-toggler" data-toggle="collapse" data-target="#navbar-collapse" aria-expanded={this.state.toggled} onClick={this.handleToggle}>
                        <span className="navbar-toggler-icon" style={{ color: 'black' }}></span>
                    </button>

                    <div className={this.state.toggled ? "collapse navbar-collapse justify-content-end show" : "collapse navbar-collapse justify-content-end"} id="navbar-collapse">

                        <NavLink to="/" className={this.state.toggled ? "nav-link-text-active" : "nav-link-text"} onClick={() => document.getElementById("home_id").scrollIntoView({ behavior: "smooth" })}>Home</NavLink>
                        <NavLink to="/services" className={this.state.toggled ? "nav-link-text-active" : "nav-link-text"} onClick={() => document.getElementById("services").scrollIntoView({ behavior: "smooth" })}>Services</NavLink>
                        <NavLink to="/mobile" className={this.state.toggled ? "nav-link-text-active" : "nav-link-text"} onClick={() => document.getElementById("mobile").scrollIntoView({ behavior: "smooth" })}>Mobile Apps</NavLink>
                        <NavLink to="/contact" className={this.state.toggled ? "nav-link-text-active" : "nav-link-text"} onClick={() => document.getElementById("contact_id").scrollIntoView({ behavior: "smooth" })}>Contact</NavLink>

                    </div>
                </div>
            </nav>
        )
    }
}