import React from 'react'

import Header from './components/header'
import Services from './pages/services'
import Index from './pages/home'
import Mobile from './pages/mobile'


const Main = () => {


    return (
        <>
            <Header />
            <Index />
            <Services />
            <Mobile />
        </>
    )

}

export default Main;