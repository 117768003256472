import React, { useEffect, useState } from "react";
import data from "../data";


const Services = () => {

    return (
        <>

            <div className="index-features" id="services">
                <div className="container">
                    <br />
                    <header>
                        <h3>Complete control over your application</h3>
                        <p>
                            We utilize state of the art project management tools giving you the utmost transparency into timelines and cost.  Time is money, so we know that properly managing your project is the best
                            and quickest way to save money.
                        </p>
                    </header>
                    <section className="features">
                        <div className="row">
                            <div className="col-md-6 feature">
                                <img src="images/icons/features-icon-ok.png" className="icon" />
                                <section>
                                    <h4>
                                        Secure platform
                                    </h4>
                                    <p>
                                        We develop all of our applications to leverage ssl technology securing even the most demanding applications.
                                    </p>
                                </section>
                            </div>
                            <div className="col-md-6 feature">
                                <img src="images/icons/features-icon-canvas.png" className="icon" />
                                <section>
                                    <h4>
                                        Looks great anywhere
                                    </h4>
                                    <p>
                                        Developing your application to transition smoothly between devices is our main objective at the core.
                                    </p>
                                </section>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 feature">
                                <img src="images/icons/features-icon-world.png" className="icon" />
                                <section>
                                    <h4>
                                        Cloud based reach
                                    </h4>
                                    <p>
                                        Access your application from anywhere with our cloud base solutions using Amazon Web Services.
                                    </p>
                                </section>
                            </div>
                            <div className="col-md-6 feature">
                                <img src="images/icons/features-icon-compass.png" className="icon" />
                                <section>
                                    <h4>
                                        Great design great outcome
                                    </h4>
                                    <p>
                                        Spacial is designed to make showing off your best projects extremely fast and simple. There are many ways to configure, just go to the source.
                                    </p>
                                </section>
                            </div>
                        </div>
                    </section>
                </div>
            </div>



            {/* <div className="index-clients">
                <div className="container">
                    <header>
                        <h4>Tech Used</h4>
                    </header>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-3 col-lg">
                            <img className="img-fluid mb-4" src="images/logos/google.jpg" />
                        </div>
                        <div className="col-12 col-md-3 col-lg">
                            <img className="img-fluid mb-4" src="images/logos/dropbox.jpg" />
                        </div>
                        <div className="col-12 col-md-3 col-lg">
                            <img className="img-fluid mb-4" src="images/logos/fb.jpg" />
                        </div>
                        <div className="col-12 col-md-3 col-lg">
                            <img className="img-fluid mb-4" src="images/logos/twitter.jpg" />
                        </div>
                        <div className="col-12 col-md-3 col-lg">
                            <img className="img-fluid mb-4" src="images/logos/instagram.jpg" />
                        </div>
                        <div className="col-12 col-md-3 col-lg">
                            <img className="img-fluid mb-4" src="images/logos/apple.jpg" />
                        </div>
                        <div className="col-12 col-md-3 col-lg">
                            <img className="img-fluid mb-4" src="images/logos/stripe.jpg" />
                        </div>
                    </div>
                </div>
            </div> */}


        </>);

};

export default Services;