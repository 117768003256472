import React, { useEffect, useState } from "react";
import data from "../data";


const Mobile = () => {

	return (
		<>




			<div className="index-features" id="mobile" style={{  backgroundColor: 'white' }}>

				<div className="container" >
					<header>
						<br />
						<h3>Mobile App Development</h3>
						<p>
							Developing your very own native mobile app is not only an operational benefit but also a way to differentiate your company from the competition.							

							Investing in development is not reserved for when times are good but is meant to push you through when the challenging times come.  
							
							Change your business into the efficient and organized powerhouse it can be!
                        </p>
					</header>
					<br />
					<section className="pricing-features">
						<div className="row">
							<div className="col-sm-6">
								<img src="images/react-native.png" className="d-block img-fluid" />
							</div>
							<div className="col-sm-6">
								<p style={{fontWeight: 400, fontSize: 26}}>
									Native Apps
                            	</p>
								<p>
									We will deliver a native app which can be accessible via the AppStore or Google Play Store
                            	</p>
								<p>
									We will develop your app using the React Native framework to create you native apps.  React Native is used to develop some of the most popular apps
                            	</p>
								<p>
									Work with all types of customers right out of the box while still getting paid in your preferred currency.
                            	</p>
							</div>
						</div>
						<div className="row">
							<div className="col-12 text-center mt-3">
								<img className="img-fluid m-3" src="images/logos/facebook.png" style={{ width: 70 }} />
								<img className="img-fluid m-3" src="images/logos/instagram.png" style={{ width: 70 }} />
								<img className="img-fluid m-3" src="images/logos/pinterest.png" style={{ width: 70 }} />
								<img className="img-fluid m-3" src="images/logos/salesforce.png" style={{ width: 70 }} />
								<img className="img-fluid m-3" src="images/logos/skype.png" style={{ width: 70 }} />
								<img className="img-fluid m-3" src="images/logos/walmart.png" style={{ width: 70 }} />
								<img className="img-fluid m-3" src="images/logos/ubereats.png" style={{ width: 70 }} />
								<img className="img-fluid m-3" src="images/logos/tesla.png" style={{ width: 70 }} />
							</div>
						</div>

					</section>
				</div>
			</div>



		</>);

};

export default Mobile;